import { styled } from '@mui/material/styles';
import React, { ComponentProps, forwardRef } from 'react';

import { Box } from '@dizzbo/ui';

const MapWrapperBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  '& .vehicleMarkerWrapper': {},
  '& .H_imprint': {
    display: 'none',
  },
}));

type BoxProps = ComponentProps<typeof Box>;

export const MapWrapper = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <MapWrapperBox ref={ref} />
));

MapWrapper.displayName = 'MapWrapper';
