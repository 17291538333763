import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Box,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { CustomerType, OrderType, UUIDType } from '@types';

type Props = {
  orderUUID: UUIDType;
  customer?: CustomerType;
};

export const CustomerDisplay: React.FC<Props> = ({ orderUUID, customer }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: mutateOrder } = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const handleDelete = (popupState) => {
    mutateOrder(
      { loader: null, invoiceReference: '', invoiceNotes: '' },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID],
          });
          toast.success(t('customerRemoved'));
        },
        onError: () => {
          toast.error(t('customerCouldNotBeRemoved'));
        },
      }
    );
    popupState.close();
  };

  const { customerNumber, name, secondName, isLocked } = customer;

  return (
    <Stack direction="row" spacing={6}>
      <Stack sx={{ minWidth: 80 }}>
        <Typography variant="labelSmall" color="primary.light">
          {t('customerNumber')}
        </Typography>
        <Typography variant="bodyRegular" color="primary.dark">
          {customerNumber}
        </Typography>
      </Stack>
      <Stack width="100%">
        <Typography variant="labelSmall" color="primary.light">
          {t('customer')}
        </Typography>
        <Typography variant="bodyRegular" color="primary.dark">
          {name} {secondName} {isLocked ? '🚫' : ''}
        </Typography>
      </Stack>
      <PopupState variant="popover" popupId="customer-popup-menu">
        {(popupState) => (
          <Box>
            <IconButton
              size="medium"
              variant="tertiary"
              {...bindTrigger(popupState)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleDelete(popupState)}>
                <ListItemIcon>
                  <ErrorIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('removeCustomer')}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </PopupState>
    </Stack>
  );
};
